@import 'styles/theme';

.PopInCloseIcon {
  position: absolute;
  width: 10px;
  padding: theme($spacings, 10);
  cursor: pointer;
  z-index: 1;
  right: theme($spacings, 15);
  top: theme($spacings, 20);

  path {
    fill: theme($colors, 'black') !important;
  }
}

.PopIn {
  position: relative;
  z-index: 1;
  box-sizing: border-box;
  background-color: theme($colors, 'white');
  overflow: hidden;
}
