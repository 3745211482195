@import 'styles/theme';

$size: 10px;

.ColorsProductHeader {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;

  margin-top: theme($spacings, 20);
  margin-bottom: theme($spacings, 25);

  padding-top: theme($spacings, 10);
  padding-bottom: theme($spacings, 10);

  @include from('lg') {
    margin-bottom: theme($spacings, 50);
  }

  .item {
    position: relative;
    box-sizing: content-box;
    width: $size;
    height: $size;
    padding: theme($spacings, 4);
    margin-left: theme($spacings, 6);
    margin-right: theme($spacings, 6);

    &:hover {
      &::before,
      &::after {
        opacity: 1;
        transform: translate3d(-50%, -50%, 0) scale3d(1, 1, 1);
      }

      .name {
        opacity: 1;
        transform: translate3d(-50%, calc(100% + 10px), 0);
      }
    }

    &.current {
      &::before,
      &::after {
        opacity: 1;
        transform: translate3d(-50%, -50%, 0) scale3d(1, 1, 1);
      }

      .name {
        opacity: 1;
        transform: translate3d(-50%, calc(100% + 10px), 0);
      }
    }

    // As the first item is align full left, we have to offset the name to stay on the grid
    &:first-child {
      margin-left: 0;

      .name {
        text-align: left;
        left: 0;
        transform: translate3d(0, calc(100% + 15px), 0);
      }

      &:hover,
      &.current {
        .name {
          transform: translate3d(0, calc(100% + 10px), 0);
        }
      }
    }

    .center {
      z-index: 2;
      position: relative;
      display: block;
      width: $size;
      height: $size;
      border-radius: 50%;
      overflow: hidden;
      box-sizing: border-box;

      &.hasBorder {
        border: 1px solid theme($colors, 'alto');
      }
    }

    .name {
      position: absolute;
      left: 50%;
      bottom: 0;
      transform: translate3d(-50%, calc(100% + 15px), 0);
      text-align: center;
      white-space: nowrap;
      opacity: 0;
      transition: 0.2s opacity, 0.3s transform theme($easings, 'ease-in-out');
    }

    &::before,
    &::after {
      content: '';
      z-index: 0;
      position: absolute;
      display: block;
      top: 50%;
      left: 50%;
      width: 100%;
      height: 100%;
      background: theme($colors, 'black');
      border-radius: 50%;
      overflow: hidden;
      transform: translate3d(-50%, -50%, 0) scale3d(0.85, 0.85, 0.85);
      opacity: 0;
      transition: 0.2s opacity, 0.3s transform theme($easings, 'ease-in-out');
      will-change: opacity, transform;
    }

    &:after {
      z-index: 1;
      width: calc(100% - 2px);
      height: calc(100% - 2px);
      opacity: 1;
      background: theme($colors, 'white');
    }
  }
}
