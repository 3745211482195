@use 'sass:math';
@import 'styles/theme';

@keyframes place-holder-shimmer {
  0% {
    background-position: -800px 0;
  }

  100% {
    background-position: 800px 0;
  }
}

.AnimationContainer {
  display: none;

  @include from('md') {
    display: flex;
    justify-content: flex-end;
    width: getGridColumnMaxWidth(18, $desktop);
  }

  @include from('xl') {
    width: getGridColumnMaxWidth(12, $desktop);
  }
}

.loader {
  position: absolute;
  width: 100%;
  height: 100%;

  z-index: 10;

  background: linear-gradient(
    to right,
    theme($colors, 'alabaster') 8%,
    theme($colors, 'gallery') 18%,
    theme($colors, 'alabaster') 33%
  );
  background-color: theme($colors, 'alabaster');
  background-size: 800px 104px;

  animation-name: place-holder-shimmer;
  animation-duration: 2s;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
}

.QuickbuyPopin {
  background-color: theme($colors, 'white');
  z-index: 1;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  .closeButton {
    width: theme($spacings, 16);
  }

  .assetWrapper {
    width: 50%;
    aspect-ratio: 312 / 426;
    position: relative;

    .image {
      position: absolute;
      width: 100%;
      height: 100%;
    }
  }

  .content {
    box-sizing: border-box;
    width: 50%;
    padding: theme($spacings, 30);
    padding-top: theme($spacings, 76);
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .productInfo {
      margin-bottom: theme($spacings, 20);

      .label {
        margin-top: theme($spacings, 4);
      }

      .colorsVariants {
        margin: theme($spacings, 20) 0;
      }
    }

    .productsActions {
      width: 100%;
      .variants {
        width: 100%;
        margin-bottom: theme($spacings, 20);

        &.product_page_fashion {
          margin-bottom: theme($spacings, 8);
        }
      }
      .addToCartButton {
        width: 100%;
      }

      .klarnaWrapper {
        margin-top: theme($spacings, 8);
      }
    }

    .productLink {
      margin-top: auto;
      text-transform: uppercase;
    }
  }

  &.skeleton {
    .content {
      gap: 20px;
    }
    .title {
      width: 100%;
      height: 18px;
      position: relative;
    }

    .cta {
      width: 100%;
      height: 41px;
      position: relative;
    }
  }
}
