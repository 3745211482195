@import 'styles/theme';

.BigButton {
  position: relative;
  border: 1px solid theme($colors, 'black');
  overflow: hidden;
  padding: theme($spacings, 12) theme($spacings, 10);
  transition: 0.2s color, 0.2s background-color;
  min-width: 52px;

  @include from('md') {
    min-width: 65px;
    padding: theme($spacings, 14) theme($spacings, 12);
  }

  &.disabled {
    opacity: 0.5;

    &:after {
      z-index: 0;
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 150%;
      height: 1px;
      background: theme($colors, 'black');
      transform: translate3d(-50%, -50%, 0) rotateZ(45deg);
    }
  }

  .childrenContent {
    position: relative;
    z-index: 1;
  }

  &.isOutOfStock {
    opacity: 0.35;
  }
}
