@import 'styles/theme';

.addToCart {
  justify-content: stretch;
  text-align: left;

  .buttonWrapper {
    display: flex;
    align-items: center;
    width: 100%;
    gap: theme($spacings, 10);

    text-align: left;
    justify-content: space-between;
  }

  .left,
  .right {
    display: inline-flex;
    align-items: center;
  }

  .icon {
    line-height: 0;
    margin-right: theme($spacings, 4);
    path {
      stroke: theme($colors, 'white');
    }
  }

  .right {
    margin-left: auto;
  }

  &.hovered {
    .icon {
      path {
        stroke: theme($colors, 'black');
      }
    }
  }
}

.crossedOutPrice {
  text-decoration: line-through;
  margin-left: theme($spacings, 10);
  transition: text-decoration 0.2s linear;
}
