@import 'styles/theme';

.VariantsFashion {
  position: relative;

  .input {
    position: relative;
    cursor: pointer;
    width: 100%;
    height: 100%;
    background: transparent;
    padding: {
      left: theme($spacings, 10);
      right: theme($spacings, 20);
      top: theme($spacings, 14);
      bottom: theme($spacings, 14);
    }
    appearance: none;
    border-radius: 0px;
    box-sizing: border-box;
    border: solid 1px theme($colors, 'black');
    transition: border 0.2s ease;

    .icon {
      position: absolute;
      line-height: 0;
      width: 1;
      width: 9px;
      height: 6px;
      transform: translateY(-50%);
      transform-origin: center center;
      top: 50%;
      right: theme($spacings, 10);
      pointer-events: none;
    }
  }

  .select {
    position: absolute;
    inset: 0;
    opacity: 0;
  }
}
