@import 'styles/theme';

.VariantsFragrance {
  display: flex;
  flex-direction: row;
  align-items: center;

  .button {
    margin-right: theme($spacings, 10);

    &:last-child {
      margin-right: 0;
    }
  }
}
