@import 'styles/theme';

.KlarnaLabel {
  display: inline-flex;
  align-items: baseline;

  .image {
    width: theme($spacings, 40);
    margin-left: theme($spacings, 4);
  }
}
