@use 'sass:math';
@import 'styles/theme';

.ProductsSlider {
  width: 100%;
  overflow: hidden;

  margin-block: theme($spacings, 40);
  @include from('md') {
    margin-block: theme($spacings, 60);
  }

  .header {
    display: flex;
    align-items: center;
    grid-column: 2 / -2;

    .title {
      flex: 1;
    }

    + .slider {
      margin-top: theme($spacings, 24);
    }
  }

  .ctaContainer {
    margin-top: theme($spacings, 20);
    grid-column: 2 / -2;
    display: flex;
    justify-content: center;

    @include from('md') {
      justify-content: flex-start;
    }
  }

  .slider {
    grid-column: 2 / -2;
    width: 100%;

    .card {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      &:not(:last-child) {
        @include applyColumns(
          (
            'propertyName': 'margin-right',
            'desktop': math.div(2, 3),
            'mobile': 0.5,
          )
        );
      }
    }

    .cardWidth {
      @include applyColumns(
        (
          'propertyName': 'width',
          'desktop': 5,
          'mobile': 10.75,
        )
      );
    }
  }
}

.SliderNavigation {
  display: flex;
  align-items: center;
  // Avoiding getting the over-padding
  margin-top: -#{theme($spacings, 7)};
  margin-bottom: -#{theme($spacings, 7)};

  .separator {
    width: 1px;
    height: theme($spacings, 10);

    &.dark {
      background-color: theme($colors, 'black');
    }

    &.light {
      background-color: theme($colors, 'white');
    }
  }

  .icon {
    display: inline-flex;
    align-items: center;
    width: 6px;
    height: 10px;
    cursor: pointer;
    padding: theme($spacings, 14);
    opacity: 0;
    pointer-events: none;

    &:first-of-type,
    &:last-of-type {
      padding-left: 0;
    }

    &.isVisible {
      opacity: 1;
      pointer-events: auto;
    }
  }
}
